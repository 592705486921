import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import type {
    ButtonProps,
    LogoSizeModifier,
    MenuContent,
} from '@pexip/components';
import {DropContent, Icon, IconTypes, Menu} from '@pexip/components';

import {InMeetingInputControl} from '../../InputControl/InMeetingInputControl.view';
import {TestId} from '../../../../test/testIds';

export const MoreControlsButton: React.FC<{
    moreControls?: MenuContent;
    iconSize: LogoSizeModifier;
    buttonVariant?: ButtonProps['variant'];
    contentClassName?: string;
    onMoreControlsVisibilityChange?: (isVisible: boolean) => void;
}> = ({
    iconSize,
    moreControls,
    buttonVariant = 'neutral',
    contentClassName,
    onMoreControlsVisibilityChange,
}) => {
    const {t} = useTranslation();
    const [showMoreControls, setShowMoreControls] = useState(false);

    if (!moreControls) {
        return null;
    }

    const moreControlsText = t('meeting.more-controls', 'More controls');
    const moreControlsContent = moreControls.filter(menu => menu.length > 0);

    return moreControls.flat().length > 0 ? (
        <DropContent
            isOpen={showMoreControls}
            position="topMiddle"
            onClose={() => setShowMoreControls(false)}
            content={<Menu menuContent={moreControlsContent} />}
            flexDirection="row"
            contentClassName={contentClassName}
            interactiveElement={(buttonProps, isContentVisible) => {
                onMoreControlsVisibilityChange?.(isContentVisible);
                return (
                    <InMeetingInputControl
                        variant={buttonVariant}
                        icon={
                            <Icon
                                source={IconTypes.IconMoreHorizontal}
                                size={iconSize}
                            />
                        }
                        isActive={isContentVisible}
                        aria-label={moreControlsText}
                        onClick={() => setShowMoreControls(!showMoreControls)}
                        data-testid={TestId.ButtonMoreControls}
                        {...buttonProps}
                    />
                );
            }}
        />
    ) : null;
};

export type MoreControlsButtonProps = React.ComponentProps<
    typeof MoreControlsButton
>;
