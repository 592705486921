import cx from 'classnames';
import React from 'react';

import {useMobileDevice, useBelow360} from '@pexip/components';
import type {ButtonProps, LogoSizeModifier} from '@pexip/components';

import type {AudioButtonProps} from './Controls/AudioButton.view';
import {AudioButton} from './Controls/AudioButton.view';
import type {LeaveButtonDesktopProps} from './Controls/LeaveButton.view';
import {LeaveButtonTouch} from './Controls/LeaveButton.view';
import type {RaiseHandButtonProps} from './Controls/RaiseHandButton.view';
import {RaiseHandButton} from './Controls/RaiseHandButton.view';
import type {VideoButtonProps} from './Controls/VideoButton.view';
import {VideoButton} from './Controls/VideoButton.view';
import type {MoreControlsButtonProps} from './Controls/MoreControlsButton.view';
import {MoreControlsButton} from './Controls/MoreControlsButton.view';

import styles from './CallControls.module.scss';

export const CallControlsTouchView: React.FC<
    React.PropsWithChildren<
        MoreControlsButtonProps &
            RaiseHandButtonProps &
            AudioButtonProps &
            VideoButtonProps &
            LeaveButtonDesktopProps
    >
> = ({
    isAudioBtnDisabled,
    isHandRaised,
    isMicrophoneMuted,
    isVideoBtnDisabled,
    isVideoMuted,
    isRaiseHandHidden,
    moreControls,
    onAudioBtnClick,
    onLeave,
    onRaiseHand,
    onVideoBtnClick,
    onMoreControlsVisibilityChange,
    children,
}) => {
    const isMobileDevice = useMobileDevice();
    const isCoreOnlyTouchCallControls = useBelow360();
    const iconSize: LogoSizeModifier = isMobileDevice ? 'medium' : 'small';
    const buttonVariant: ButtonProps['variant'] = 'neutral';

    return (
        <div className={cx(styles.callControlsWrapper, styles.touchWrapper)}>
            <div className={styles.touchCallControlBtnWrapper}>
                {children}
                <MoreControlsButton
                    buttonVariant={buttonVariant}
                    iconSize={iconSize}
                    moreControls={moreControls}
                    contentClassName={styles.touchMoreMenu}
                    onMoreControlsVisibilityChange={
                        onMoreControlsVisibilityChange
                    }
                />
                {!isCoreOnlyTouchCallControls && (
                    <RaiseHandButton
                        variant={buttonVariant}
                        isHandRaised={isHandRaised}
                        onRaiseHand={onRaiseHand}
                        iconSize={iconSize}
                        isRaiseHandHidden={isRaiseHandHidden}
                    />
                )}
                <AudioButton
                    variant={buttonVariant}
                    isMicrophoneMuted={isMicrophoneMuted}
                    isAudioBtnDisabled={isAudioBtnDisabled}
                    onAudioBtnClick={onAudioBtnClick}
                    iconSize={iconSize}
                />
                <VideoButton
                    variant={buttonVariant}
                    isVideoMuted={isVideoMuted}
                    isVideoBtnDisabled={isVideoBtnDisabled}
                    onVideoBtnClick={onVideoBtnClick}
                    iconSize={iconSize}
                />
                <LeaveButtonTouch onLeave={onLeave} iconSize={iconSize} />
            </div>
        </div>
    );
};

export type CallControlsTouchProps = React.ComponentProps<
    typeof CallControlsTouchView
>;
